import React, {useContext, useEffect, useState} from "react";
import IODefinitionStore from "../../stores/IODefinitionStore";
import {AuthContext} from "../../ResponsiveApp";
import Triggers from "../../api/triggerApi";
import HttpCodes from "../../library/HttpCodes";
import TriggerConditionList from "./TriggerConditionList";
import TriggerNotifyEditor from "./TriggerNotifyEditor";
import TriggerNodeList from "./TriggerNodeList";
import TriggerNode from "./TriggerNode";
import moment from "moment/moment";
import TriggerEditor from "./TriggerEditor";
import {NavigationContext} from "../navigation/DolphinNavigator";
import ButtonGlyph from "../../library/buttonGlyph";



const TriggerManager = ({target_lift, liftState, move, liftData, dataViewSet}) => {
    const {authorisation} = useContext(AuthContext)
    const { confirmOptions, setConfirmOptions} = useContext(NavigationContext)


    const [ioDefinitions, setIoDefinitions] = useState(null) ;
    const [fwRevisions, setFWRevisions] = useState([]) ;

    const [allusers, setAllUsers] = useState(null) ;
    const [allGroups, setAllGroups] = useState(null) ;
    const [hbTriggers, setHbTriggers] = useState(null) ;
    const [gbTriggers, setGbTriggers] = useState(null) ;
    const [dvcTriggers, setDvcTriggers] = useState(null) ;
    const [liftTriggers, setLiftTriggers] = useState(null) ;
    const [filteredHbTriggers, setFilteredHbTriggers] = useState(null) ;
    const [filteredGbTriggers, setFilteredGbTriggers] = useState(null) ;
    const [filteredDvcTriggers, setFilteredDvcTriggers] = useState(null) ;

    const [editing, setEditing] = useState(false) ;
    const [activeTrigger, setActiveTrigger] = useState(null) ;
    const [evaluationResult, setEvaluationResult] = useState(null) ;
    const [canDelete, setCanDelete] = useState(false) ;
    const [hideInvalidTriggers, setHideInvalidTriggers] = useState(true) ;

    useEffect(() => {
        // console.log("TriggerList: useEffect, target_lift -> ", target_lift) ;
        const fetchData = async () => {
            let io = await IODefinitionStore.io(true) ;
            setIoDefinitions(io) ;
            await searchTriggers({}) ;
        }

        fetchData() ;
    }, []);

    useEffect(() => {
        if (ioDefinitions) {
            let fw = ioDefinitions.fw_maps
            if (fw) {
                let items = Object.values(fw) ;
                setFWRevisions(items) ;
            }
        }
        else {
            console.log("No IO Definitions available") ;
        }
    }, [ioDefinitions]);

    const refresh = async () => {
        await searchTriggers({}) ;
    }

    const loadTriggerById = async (trigger_id) => {
        try {
            let result = await Triggers.getTrigger(1) ;
            console.log("Trigger Result -> ", result) ;
        }
        catch (e) {
            console.log("Error loading trigger", e) ;
        }
    }

    const searchTriggers = async (criteria) => {
        try {
            let result = await Triggers.search(criteria) ;
            console.log("Trigger Search Result -> ", result) ;
            if (result.status === HttpCodes.HttpOK) {
                let triggers = result.data.triggers ;
                let hb = [] ;
                let gb = [] ;
                let dvc = [] ;
                triggers.forEach((trigger) => {
                    if (trigger.is_heartbeat) {
                        hb.push(trigger) ;
                    }
                    else if (trigger.is_global) {
                        gb.push(trigger) ;
                    }
                    else if (!trigger.is_heartbeat && !trigger.is_global) {
                        dvc.push(trigger) ;
                    }
                }) ;

                setAllUsers(result.data.users) ;
                setAllGroups(result.data.groups) ;

                setHbTriggers(hb) ;
                setLiftTriggers(null) ;
                setFiltered(hideInvalidTriggers, gb, dvc) ;
            }
            else {
                console.log("Error searching triggers", result);
                setHbTriggers(null);
                setGbTriggers(null);
                setDvcTriggers(null);
                setLiftTriggers(null);
            }
        }
        catch (e) {
            console.log("Error searching triggers", e) ;
        }
    }

    const setFiltered = (hide, gb, dvc) => {
        setGbTriggers(gb) ;
        setDvcTriggers(dvc) ;

        console.log("Set Filtered -> ", hide, gb, dvc) ;
        if (target_lift) {
            if (hide) {
                if (dvc) {
                    let filtered = dvc.filter((trigger) => {
                        return trigger.firmware_target === target_lift.firmware_revision ;
                    }) ;

                    setFilteredDvcTriggers(filtered) ;
                }
                else {
                    setFilteredDvcTriggers(null)
                }

                if (gb) {
                    let gb_filtered =gb.filter((trigger) => {
                        return trigger.firmware_target === target_lift.firmware_revision ;
                    })

                    setFilteredGbTriggers(gb_filtered) ;
                }
                else {
                    setFilteredGbTriggers(null) ;
                }
            }
            else {
                setFilteredGbTriggers(gb) ;
                setFilteredDvcTriggers(dvc) ;
            }
        }
        else {
            setFilteredGbTriggers(gb) ;
            setFilteredDvcTriggers(dvc) ;
        }

    }


    const hideNotApplicable = (hide) => {
        console.log("Hide Not Applicable -> ", hide, gbTriggers, dvcTriggers) ;
        setFiltered(hide, gbTriggers, dvcTriggers) ;
        setHideInvalidTriggers(hide) ;
    }

    const buildNewTriggerTemplate = (is_hb, is_gb) => {
        return {
            trigger_id : null,
            is_heartbeat : is_hb,
            is_global : is_gb,
            is_active : true,
            is_deleted : false,
            is_locked : false,
            firmware_target : null,
            item_count : 0,
            timer : null,
            contacts : null,
            raw_structure : [],
            expr_structure : null,
        }
    }

    const createNewTrigger = () => {
        let new_trigger = buildNewTriggerTemplate(false, false) ;
        setActiveTrigger(new_trigger) ;
        setEditing(true) ;
    }

    const editRequested = () => {
        setEditing(true) ;
    }

    const editCompleted = (status) => {
        if (status.cancelled) {
            setActiveTrigger(null) ;
        }

        setEditing(false) ;
    }

    const showDetail = (trigger) => {
        setActiveTrigger(trigger) ;
        if (trigger) {
            let cd = authorisation.isAdmin || trigger.created_by === authorisation.account.userid ;
            setCanDelete(cd) ;
        }
    }

    const closeDetail = () => {
        setActiveTrigger(null) ;
    }

    const evaluateAdc = (expr, row) => {
        let adc = null
        switch (expr.target.adc) {
            case 'A' : adc = row.adc_bits_a ; break ;
            case 'B' : adc = row.adc_bits_b ; break ;
            case 'C' : adc = row.adc_bits_c ; break ;
            default: adc = null ;
        }

        if (!adc) {
            return false ;
        }

        let actual_value = adc[expr.target.bit - 1] ;
        // console.log("term Actual Value -> ", adc, " bit ", expr.target.bit, " actual ", actual_value, " for target -> ", expr) ;
        if (expr.value === "LOW") {
            return actual_value === '0' ;
        }
        else if (expr.value === "HIGH") {
            return actual_value === '1' ;
        }
    }

    const evaluateExprWithRow = (expr, row) => {
        let result = false ;

        if (!expr) {
            return false ;
        }

        // console.log("Evaluate Expression -> ", expr, " with row -> ", row) ;
        if (expr.type === 'TERM') {
            if (expr.target.adc) {
                return evaluateAdc(expr, row);
            }
            else if (expr.target.data_key) {
                let data_value = row[expr.target.key] ;
                switch (expr.target.datatype) {
                    case 'int' : data_value = parseInt(data_value) ; break ;
                    case 'float' : data_value = parseFloat(data_value) ; break ;
                    default: break ;
                }

                // console.log("Evaluate Data Key -> ", expr.target.key, " with value -> ", data_value, " against ", expr.value) ;

                if (expr.target.is_lookup) {
                    // need to find the key in an object where the value matches the expr.value field
                    let values = Object.values(expr.target.lookup) ;
                    let target_value = values.indexOf(expr.value) ;

                    // console.log("Lookup Values -> ", data_value, " target_value -> ", target_value, " match -> ", data_value === target_value) ;
                    return data_value === target_value ;
                }
                else {
                    switch (expr.op) {
                        case 'EQ' :
                            return data_value === expr.value;
                        case 'NE' :
                            return data_value !== expr.value;
                        case 'GT' :
                            // let r = data_value > expr.value ;
                            // console.log("GT -> ", data_value, " > ", expr.value, " = ", r) ;
                            return data_value > expr.value;
                        case 'GE' :
                            // let rx = data_value >= expr.value ;
                            // console.log("GE -> ", data_value, " >= ", expr.value, " = ", rx) ;
                            return data_value >= expr.value;
                        case 'LT' :
                            return data_value < expr.value;
                        case 'LE' :
                            return data_value <= expr.value;
                        default:
                            result = false;
                    }
                }
            }
        }
        else if (expr.type === 'EXPR') {
            let left_result = evaluateExprWithRow(expr.left, row) ;
            let right_result = evaluateExprWithRow(expr.right, row) ;
            // console.log("EXPR left_result -> ", left_result, " right_result -> ", right_result) ;
            switch (expr.op) {
                case 'AND' : return left_result && right_result ;
                case 'OR' : return left_result || right_result ;
                default: result = left_result ;
            }
        }

        return result ;
    }


    const testEvaluation = (tr, data) => {
        let expr = tr.expr_structure ;
        if (!expr) {
            return ;
        }

        let trigger_count = 0 ;
        let triggered = [] ;
        for (let row of data) {
            // evaluate each row in turn
            let result = evaluateExprWithRow(expr, row) ;
            triggered.push({
                received_at : row.received_at,
                triggered : result
            })

            trigger_count += result ? 1 : 0 ;
        }

        console.log("Trigger ---- ", trigger_count, " out of ", data.length) ;
        return {trigger_count, num_data_points: data.length, data: triggered} ;
    }

    const testTimedEvaluation = (tr, data) => {
        let expr = tr.expr_structure ;
        if (!expr) {
            return ;
        }

        // need to convert the timer into a time range
        let timer = tr.timer ;
        let range = moment.duration(timer.value, timer.unit).asMilliseconds() ;
        console.log("RUNNING TIMED EVALUATION - ", tr.timer, " over range ", range, "ms") ;


        let trigger_count = 0 ;
        let triggered = [] ;
        let triggered_tmp = [] ;
        for (let row of data) {
            // evaluate each row in turn
            let result = evaluateExprWithRow(expr, row) ;

            /*
             -- we want to evaluate over a time range. When first triggered we start adding to the triggered_tmp array and make a note of the start time
             -- when the result is false and if the current time is within the range we add any entries in the triggered_tmp array to the triggered array (not triggered).
             -- if the result is false and the current time exceeds the range then we mark all the entries in the triggered_tmp array as triggered and add them to the triggered array
             */

            if (result) {
                triggered_tmp.push({
                    received_at : row.received_at,
                    triggered : result
                })
            }
            else {
                if (triggered_tmp.length > 0) {
                    let first = triggered_tmp[0] ;
                    let first_time = moment.utc(first.received_at) ;
                    let current_time = moment.utc(row.received_at) ;
                    let diff = current_time.diff(first_time) ;
                    if (diff > range) {
                        // console.log("Range Exceeded -> ", diff, " > ", range) ;
                        triggered_tmp.forEach((t) => {
                            t.triggered = true ;
                        }) ;
                        triggered.push(...triggered_tmp) ;
                        trigger_count += 1 ;
                        triggered_tmp = [] ;
                    }
                    else {
                        // console.log("Within Range -> ", diff, " < ", range) ;
                        triggered_tmp.forEach((t) => {
                            t.triggered = false ;
                        }) ;
                        triggered.push(...triggered_tmp) ;
                        triggered_tmp = [] ;
                    }
                }
                else {
                    triggered.push({
                        received_at : row.received_at,
                        triggered : result
                    })
                }
            }

        }

        if (triggered_tmp.length > 0) {
            triggered_tmp.forEach((t) => {
                t.triggered = true ;
            }) ;
            triggered.push(...triggered_tmp) ;
            trigger_count += 1 ;
        }

        console.log("Trigger ---- ", trigger_count, " out of ", data.length) ;
        return {trigger_count, num_data_points: data.length, data: triggered} ;
    }


    const runEvaluation = () => {
        console.log("Run Evaluation on trigger -> ", activeTrigger) ;
        // console.log("Run Evaluation on lift -> ", target_lift) ;
        // console.log("Lift Data -> ", liftData) ;
        // console.log("Data View Set -> ", dataViewSet) ;
        // console.log("Lift State -> ", liftState) ;

        let trigger_info = activeTrigger.timer ? testTimedEvaluation(activeTrigger, liftData.data) : testEvaluation(activeTrigger, liftData.data) ;
        console.log("Evaluation Trigger Ranges -> ", trigger_info) ;
        if (trigger_info.trigger_count > 0) {
            console.log("Triggered -> ", trigger_info.trigger_count) ;
            refreshTriggerChart(trigger_info.data) ;
        }
        else {
            let log_timeline = document.getElementById(`trigger_timeline`);
            if (log_timeline) {
                log_timeline.innerHTML = "No triggers found in the selected data range" ;
            }
        }

        setEvaluationResult(trigger_info) ;
    }

    const refreshTriggerChart = (triggered) => {
        let log_timeline = document.getElementById(`trigger_timeline`);
        if (!log_timeline) {
            return ;
        }

        drawTriggerRangeChart(log_timeline, triggered) ;
    }

    const drawTriggerRangeChart = (container, data) => {
        var chart = new window.google.visualization.Timeline(container);
        var dataTable = new window.google.visualization.DataTable();

        // console.log("Timeline selected LOGS: ", data.num_data_points, data.f301, data.l475)
        let dx = [];
        let inTrip = false ;
        let tripStart = null ;
        let tripEnd = null ;
        let tripCount = 0 ;

        if (data.length > 0) {
            let startDate = moment.utc(data[0].received_at).toDate();
            let endDate = moment.utc(data[data.length - 1].received_at).toDate();

            dx.push([
                "Trigger",
                "Start",
                startDate,
                startDate,
                null
            ]);

            dx.push([
                "Trigger",
                "End",
                endDate,
                endDate,
                null
            ]);
        }

        for (let ix = 0 ; ix < data.length ; ix++) {
            if (data[ix].triggered && !inTrip) {
                tripStart = moment.utc(data[ix].received_at).toDate() ;
                inTrip = true ;
            }
            else if (!data[ix].triggered && inTrip) {
                tripEnd = moment.utc(data[ix].received_at).toDate() ;

                dx.push([
                    "Trigger",
                    `Trigger - ${activeTrigger.trigger_name}`,
                    tripStart,
                    tripEnd,
                    null
                ]);

                tripCount += 1 ;
                inTrip = false ;
            }
        }

        if (inTrip) {
            tripEnd = moment.utc(data[data.length -1].received_at).toDate() ;
            dx.push([
                "Trigger",
                `Trigger - ${activeTrigger.trigger_name}`,
                tripStart,
                tripEnd,
                null
            ]);

            tripCount += 1 ;
        }

        // A frig to get items without transitions to display on the chart
        if (tripCount === 0) {
            dx.push([
                "Trigger",
                `Trigger - ${activeTrigger.trigger_name}`,
                moment.utc(data[0].received_at).toDate(),
                moment.utc(data[data.length -1].received_at).toDate(),
                null
            ]);
        }

        // console.log("Trigger Timeline Data -> ", dx) ;

        let sc = {} ;
        dataTable.addColumn({type: 'string', id: 'Log'});
        dataTable.addColumn({type: 'string', id: 'Name'});
        dataTable.addColumn({type: 'date', id: 'Start'});
        dataTable.addColumn({type: 'date', id: 'End'});
        dataTable.addColumn({ type: 'string', role: 'annotation' });
        dataTable.addRows(dx);

        var options = {
            chartArea: {left: 0, right: 0, top: 0, bottom: 0},
            timeline: {
                showRowLabels: false,
                barLabelStyle : {fontSize : 11},
            },
            hAxis: {
                format: 'M/d/yy',
                textPosition: "none",
                gridlines: {count: 5}
            },
            tooltip: {isHtml: true},
            legend: 'none',
        };

        var formatTime = new window.google.visualization.DateFormat({pattern: 'HH:mm:ss a'});

        const selectHandler = (e) => {
            let sel = chart.getSelection();
            if (sel.length === 1) {
                let target_time = moment(dataTable.getValue(sel[0].row, 2)) ;
                console.log("Selected Time -> ", target_time, " Current ", liftState) ;
                let ts = liftData.data.findIndex((d) => moment(d.received_at).isSame(target_time)) ;
                if (ts >= 0) {
                    move(ts + 1)
                }
            }
        }
        var view = new window.google.visualization.DataView(dataTable);
        view.setColumns([0, 1, {
            role: 'tooltip',
            type: 'string',
            calc: function (dt, row) {
                // build tooltip
                var dateBegin = dt.getValue(row, 2);
                var dateEnd = dt.getValue(row, 3);
                let ms = moment(dateBegin) ;
                let me = moment(dateEnd) ;

                var dd = moment.duration(me.diff(ms)) ;
                let duration = moment.utc(dd.asMilliseconds()).format("HH:mm:ss");
                var tooltip = '<div><div class="ggl-tooltip"><span>';
                tooltip += dt.getValue(row, 1) + '</div>';
                tooltip += '<div class="ggl-tooltip"><span>Selected Range</span><br/> <div>' + formatTime.formatValue(dateBegin) + ' - ';
                tooltip += formatTime.formatValue(dateEnd) + '</div>';
                tooltip += '<div><span>Duration: </span>' + duration + '</div></div>';
                return tooltip;
            },
            p: {html: true}
        }, 2, 3, 4]);

        container.style.height = `${3 * 55}px`;
        window.google.visualization.events.addListener(chart, 'select', selectHandler);
        chart.draw(view.toDataTable(), options);
    }

    const deleteTrigger = async (trigger) => {
        try {
            let result = await Triggers.deleteTrigger(trigger.trigger_id) ;
            console.log("Delete Trigger Result -> ", result) ;
            if (result.status === HttpCodes.HttpOK) {
                setActiveTrigger(null) ;
                await searchTriggers({}) ;
            }
        }
        catch (e) {
            console.log("Error deleting trigger", e) ;
        }
    }

    const openDeleteConfirm = (title, msg, action, target) => {
        console.log("Opening confirmation: ", target);

        let options = {
            title: title,
            message: msg,
            onConfirm: (confirmation) => deleteTrigger(confirmation.target),
            onCancel: (confirmation) => console.log("Cancelled: ", confirmation),
            action : action,
            target : target
        }

        setConfirmOptions(options) ;
    }

    const requestDeleteTrigger = (trigger) => {
        console.log("Delete Trigger -> ", trigger) ;
        openDeleteConfirm("Delete Trigger", `Are you sure you want to delete the trigger ${trigger.trigger_name}`, "delete", trigger) ;
    }


    return (
        <div className="content">

            {(!editing) &&
                <>
                    {(!activeTrigger) &&
                        <>
                            <button className={"button button-refresh"} onClick={refresh}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>refresh</span>Refresh</button>
                            <button className={"button button-info"} onClick={createNewTrigger}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>add</span>Create a new Query Trigger</button>

                            {(target_lift) &&
                                <div style={{marginLeft: '20px', display: 'inline-block'}}>
                                    <ButtonGlyph glyph={hideInvalidTriggers ? "check_box" : "check_box_outline_blank"}
                                                 cn="material-symbols-rounded"
                                                 onClick={() => hideNotApplicable(!hideInvalidTriggers)}
                                                 style={{
                                                     fontSize: '24px',
                                                     verticalAlign: 'middle',
                                                     color: 'darkgray',
                                                     width: '40px'
                                                 }}/>
                                    <small><i>{hideInvalidTriggers ? "Hide any not applicable" : "Showing all triggers"}</i></small>
                                </div>
                            }

                            {target_lift && <TriggerNodeList triggers={liftTriggers} title={"Attached to this lift"} subtitle={'(applied to this lift)'} icon={"elevator"} onShowDetail={showDetail}/>}
                            <TriggerNodeList triggers={hbTriggers} title={"Heartbeat"} subtitle={'(applied to all lifts)'} icon={"ecg_heart"} onShowDetail={showDetail}/>
                            <TriggerNodeList triggers={filteredGbTriggers} title={"Global"} subtitle={'(applied to all lifts)'} icon={"globe"} onShowDetail={showDetail}/>
                            <TriggerNodeList triggers={filteredDvcTriggers} title={"Lift Specific"} subtitle={'(applied to specific lifts)'} icon={"elevator"} onShowDetail={showDetail}/>
                        </>
                    }

                    {(activeTrigger && !editing) &&
                        <>
                            <div>
                                <button className={"button "} onClick={closeDetail}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>cancel</span>Close Detail</button>
                                <button className={"button button-save"} onClick={() => editRequested()}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>edit</span>Edit</button>
                                {canDelete && <button className={"button button-cancel"} onClick={() => requestDeleteTrigger(activeTrigger)}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>delete</span>Remove Trigger</button>}
                            </div>

                            {(target_lift && activeTrigger && !activeTrigger.is_heartbeat) &&
                                <div style={{width: '95%'}}>
                                    <div className="section-header">Evaluation Playground</div>
                                    <p><i>Run this trigger against the currently selected data range to check for any violations</i></p>
                                    <button className={"button button-info"} disabled={dataViewSet?.empty} onClick={runEvaluation}><span className="material-icons" style={{verticalAlign: '-7px', paddingRight: '8px'}}>directions_run</span>Run on Selected Data</button>
                                    <div style={{backgroundColor: 'white'}}>
                                        <h4>Active </h4>
                                        <div id="trigger_timeline" style={{width: '100%'}}>&nbsp;</div>
                                    </div>
                                </div>
                            }

                            <div className="section-header">Definition</div>
                            <div className="item-container-list" style={{marginTop: '30px'}}>

                            <TriggerNode key={`e_etr`} trigger={activeTrigger} onShowDetail={showDetail} allowSelect={false}/>

                                <div style={{marginLeft: '10px', marginRight: '10px', textAlign: 'center'}}>
                                    <div className={"fw-text"}>Over Period</div>
                                    <span className="material-icons" style={{fontSize: '64px'}}>arrow_right_alt</span>
                                </div>
                                <div key={"e_tm"} className={"timer-container"}>
                                    <div style={{width: '100%'}}>
                                        <div style={{display: 'inline-block'}}>
                                            <div style={{padding: '2px'}}><b>Timer</b></div>
                                            <span className="material-icons" style={{fontSize: '56px', color: `${activeTrigger.timer ? "green" : "black"}`}}>{activeTrigger.timer ? "timer" : "timer_off"}</span>
                                        </div>
                                        <div style={{display: 'inline-block', verticalAlign: 'top', marginTop: '20px'}}>
                                            {activeTrigger.timer &&
                                                <div style={{display: 'inline-block'}}>
                                                    <input type={"number"} style={{width: '50px'}} readOnly value={activeTrigger.timer.value || ""}/>
                                                    <select style={{marginLeft: '10px', marginRight: '10px', marginTop: '5px'}} defaultValue={activeTrigger.timer.unit || ""}>
                                                        <option key={'t_seconds'} value={'seconds'}>second(s)</option>
                                                        <option key={'t_minutes'} value={'minutes'}>minute(s)</option>
                                                        <option key={'t_hours'} value={'hours'}>hour(s)</option>
                                                        <option key={'t_days'} value={'days'}>day(s)</option>
                                                    </select>
                                                </div>
                                            }
                                            {(!activeTrigger.timer) && <div className={"fw-tm-cell"} style={{marginRight: '10px'}}>immediate</div>}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {(!activeTrigger.is_heartbeat) &&
                                <div style={{marginTop: '50px'}}>
                                    <div className="section-header">Current Evaluation Criteria</div>
                                    <TriggerConditionList trigger={activeTrigger} ioDefinitions={ioDefinitions}/>
                                </div>
                            }

                            <TriggerNotifyEditor trigger={activeTrigger} editing={editing} allUsers={allusers} allGroups={allGroups}/>
                        </>
                    }
                </>
            }

            {(activeTrigger && editing) &&
                <>
                    <TriggerEditor targetTrigger={activeTrigger} ioDefinitions={ioDefinitions} fwRevisions={fwRevisions} onEditComplete={editCompleted}/>
                </>
            }

        </div>
    )
}

export default TriggerManager;