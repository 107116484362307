import Helper from "../../../library/Helper";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DisplayContext} from "../../../ResponsiveApp";
import {LiftDataContext} from "../LiftDataNavigatorContext";
import DateSelectType from "../../../library/DateSelectType";
import moment from "moment";
import {DisplaySizeClass} from "../../navigation/DisplaySizeClass";
import ButtonGlyph from "../../../library/buttonGlyph";

const LiftStatusDateSelect = ({summary_only}) => {
    const display = useContext(DisplayContext)
    const {
        lift, findData, liftData, liftState, dataViewSet, moveToSelectedTime, move, advance, play, stop,
        playing, errors, loading, dateType, setDateType, startDate, setStartDate, setDateRange,
        endDate, setEndDate, pauseOnSafetySignal, setPauseOnSafetySignal, loadData, onEndDateChange, onStartDateChange,
        filterStartDate, setFilterStartDate, filterEndDate, setFilterEndDate, setFilterRange, clearFilterRange,
        showDateChart, setShowDateChart
    } = useContext(LiftDataContext);

    const [distributionSummary, _setDistributionSummary] = useState([]);

    const [LookupF301, setLookupF301] = useState(null);
    const [LookupL475, setLookupL475] = useState(null);

    useEffect(() => {
        setLookupF301(lift?.io_definition?.F301Lookup);
        setLookupL475(lift?.io_definition?.L475Lookup);
    }, [lift]);

    const distributionSummaryRef = useRef(null);
    const setDistributionSummary = data => {
        distributionSummaryRef.current = data;
        _setDistributionSummary(data);
    };


    useEffect(() => {
        createDataDistributionSummary(dataViewSet)
    }, [liftData, dataViewSet, summary_only, showDateChart]);

    const createDataDistributionSummary = (dataset) => {
        let result = [];
        let temp = null;

        let chart_div = document.getElementById('dist_chart_id');
        if (dataset.empty || (summary_only && !showDateChart)) {
            // console.log("Empty Data Set: ", dataset.empty, " Show Chart: ", showDateChart, " Summary Only: ", summary_only)
            if (chart_div) {
                chart_div.hidden = true;
            }

            setDistributionSummary([])
            return ;
        }

        let data = dataViewSet.data;
        let previousRow = null;
        for (let item of data) {
            let timestamp = moment.utc(item.received_at);
            timestamp.minutes(Math.floor(timestamp.minutes() / 30) * 30); // Round down to nearest 30 minutes
            timestamp.seconds(0);
            timestamp.milliseconds(0);

            // Convert the timestamp to the local timezone
            let localTimestamp = timestamp.local().toDate();

            if (temp && temp.timestamp.getTime() === localTimestamp.getTime()) {
                // If the timestamp is within the current 30-minute window, increment the counts
                temp.totalCount++;
                temp.window_last = moment.utc(item.received_at);
                if (item.common_safety_chain_item_id) {
                    if (previousRow && previousRow.common_safety_chain_item_id !== item.common_safety_chain_item_id) {
                        temp.commonCount++;
                        if (temp.safety[item.common_safety_chain_desc]) {
                            temp.safety[item.common_safety_chain_desc]++;
                        } else {
                            temp.safety[item.common_safety_chain_desc] = 1;
                        }
                    }
                }

                if (item.s_f301_sr !== LookupF301?.byLabel.LOG_NONE) {
                    if (previousRow && previousRow.s_f301_sr !== item.s_f301_sr) {
                        temp.f301count++;
                        let label = LookupF301?.descriptions[item.s_f301_sr];
                        if (temp.f301log[label]) {
                            temp.f301log[label]++;
                        } else {
                            temp.f301log[label] = 1;
                        }
                    }
                }

                if (item.s_f475_sr !== LookupL475?.byLabel.LOG_NONE) {
                    if (previousRow && previousRow.s_f475_sr !== item.s_f475_sr) {
                        temp.l475count++;
                        let label = LookupL475?.descriptions[item.s_f475_sr];
                        if (temp.l475log[label]) {
                            temp.l475log[label]++;
                        } else {
                            temp.l475log[label] = 1;
                        }
                    }
                }

            } else {
                // If the timestamp is outside the current 30-minute window, start a new window
                if (temp) {
                    result.push(temp)
                }

                temp = {
                    window_first: moment.utc(item.received_at),
                    timestamp: localTimestamp,
                    totalCount: 1,
                    commonCount: item.common_safety_chain_item_id ? 1 : 0,
                    f301count : item.s_f301_sr !== LookupF301?.byLabel.LOG_NONE ? 1 : 0,
                    l475count : item.s_f475_sr !== LookupL475?.byLabel.LOG_NONE ? 1 : 0,
                    safety: {},
                    f301log: {},
                    l475log: {}
                }

                if (item.common_safety_chain_item_id) {
                    temp.safety[item.common_safety_chain_desc] = 1
                }

                if (item.s_f301_sr !== LookupF301?.byLabel.LOG_NONE) {
                    let label = LookupF301?.descriptions[item.s_f301_sr];
                    temp.f301log[label] = 1;
                }

                if (item.s_f475_sr !== LookupL475?.byLabel.LOG_NONE) {
                    let label = LookupL475?.descriptions[item.s_f475_sr];
                    temp.l475log[label] = 1;
                }
            }

            previousRow = item;
        }

        // Push the last 30-minute window to the result
        if (temp) {
            temp.window_last = moment.utc(previousRow.received_at);
            result.push(temp);
        }

        setDistributionSummary(result)

        if (result.length > 0) {
            chart_div.hidden = false;
            drawDistributionChart(result)
        } else {
            setDistributionSummary([])
            chart_div.hidden = true;
        }
    }

    const handleRowSelect = (data, row) => {
        let item = data[row]
        let ds = distributionSummaryRef.current;
        if (ds && row >= 0 && row < ds.length) {
            let selected = ds[row];
            moveToSelectedTime(selected.window_first)
        } else {
            console.log("Invalid Row Selected: ", row, ds)
        }
    }


    const drawDistributionChart = (distribution) => {
        let chartData = new window.google.visualization.DataTable();
        chartData.addColumn('datetime', 'Time of Day');
        chartData.addColumn('number', 'Total Records');
        chartData.addColumn('string', 'title1');
        chartData.addColumn('string', 'text1');
        chartData.addColumn('number', 'Main Safety Chain');
        chartData.addColumn('number', 'F301 Logs');
        chartData.addColumn('number', 'L475 Logs');

        let data = distribution.map((item) => {
            let time = item.timestamp;

            let safety_annotation = null
            if (item.commonCount > 0 || item.f301count > 0 || item.l475count > 0) {
                safety_annotation = "<div style='padding: 5px; background-color: #f8dcdc; color: #010901; border-radius: 5px;'>" +
                    "<b>Time: </b>" + Helper.dbTimeSecsToDisplayTimeUTC(time) + "<br/>" +
                    "<b>Safety Count = </b>" + item.commonCount + ",  " +
                    "<b>F301 Count = </b>" + item.f301count + ",  " +
                    "<b>L475 Count = </b>" + item.l475count + "<br/>" +
                    "<br/>";

                if (item.commonCount > 0) {
                    safety_annotation += "<div style='font-size: larger'>Safety</div>"

                    for (let key in item.safety) {
                        safety_annotation += "<span> => [" + item.safety[key] + "] " + key + "</span><br/>"
                    }
                }
                safety_annotation += "<br/>";
                if (item.f301count > 0) {
                    safety_annotation += "<div style='font-size: larger'>F301</div>"

                    for (let key in item.f301log) {
                        safety_annotation += "<span> => [" + item.f301log[key] + "] " + key + "</span><br/>"
                    }
                }
                safety_annotation += "<br/>";
                if (item.l475count > 0) {
                    safety_annotation += "<div style='font-size: larger'>L475</div>"

                    for (let key in item.l475log) {
                        safety_annotation += "<span> => [" + item.l475log[key] + "] " + key + "</span><br/>"
                    }
                }

                safety_annotation += "</div>"
            }

            return [
                new Date(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours(), time.getMinutes(), time.getSeconds()),
                item.totalCount,
                safety_annotation ? "Events" : null,
                safety_annotation,
                item.commonCount,
                item.f301count,
                item.l475count
            ];
        });

        chartData.addRows(data);

        let options = {
            title: 'Lift Data Distribution',
            displayAnnotations: true,
            displayAnnotationsFilter: true,
            allowHtml: true,
            vAxis: {
                title: 'Number of Data Points'
            },
            legend: {
                position: 'top'
            },
            series: {
                0: {color: '#FF0000', areaOpacity: 0.1},
                1: {color: '#00FF00', areaOpacity: 0.4},
                2: {color: '#0000FF', areaOpacity: 0.1},
                3: {color: '#000000', areaOpacity: 0.1}
            },
            isStacked: true,
            areaOpacity: 0.1,
            pointSize: 5,
            thickness: 3,
            fill: 15,
            pointShape: 'square',
            tooltip: {
                isHtml: true
            }
        };

        let chart = new window.google.visualization.AnnotationChart(document.getElementById('distribution_chart'));
        chart.draw(chartData, options);

        const selectHandler = (e) => {
            let sel = chart.getSelection();
            if (sel.length >= 1) {
                console.log("SELECTED: ", sel)
                handleRowSelect(data, sel[0].row)

                // console.log("CHART DATA: ", chartDataRef.current)
                // SystemEvents.emit('DATA-POINT-SELECTED', sel[0].row + 1) ;
            }
        }

        const rangechange_handler = (e) => {
            setFilterStartDate(e['start'])
            setFilterEndDate(e['end'])
        }

        window.google.visualization.events.addListener(chart, 'rangechange', rangechange_handler);
        window.google.visualization.events.addListener(chart, 'select', selectHandler);
    }

    const applyFilter = () => {
        setFilterRange(filterStartDate, filterEndDate)
    }

    const clearFilter = () => {
        clearFilterRange()
    }

    return (
        <div className={"lift-date-select-bg"}>
            <div style={{marginLeft: '10px', marginTop: '10px'}}>

                {(display.size_class === DisplaySizeClass.MobilePortrait) &&
                    <>
                        <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '200px', display: 'inline-block'}}>Select Start
                                        Date/Time</label>
                                    <input type="datetime-local" style={{display: 'inline-block'}} value={startDate}
                                           onChange={(d) => onStartDateChange(d.target.value)}/>
                                </div>
                            </div>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '200px', display: 'inline-block'}}>Select End
                                        Date/Time</label>
                                    <input type="datetime-local" style={{display: 'inline-block'}}
                                           value={endDate} onChange={(d) => onEndDateChange(d.target.value)}/>
                                </div>
                            </div>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '200px', display: 'inline-block'}}>Choose Predefined Period</label>
                                    <div style={{display: 'inline-block'}}>
                                        <div
                                            className={dateType === DateSelectType.Last5Mins ? "segment-select-item-selected " : "segment-select-item"}
                                            style={{minWidth: '60px'}}
                                            onClick={() => setDateRange(DateSelectType.Last5Mins)}>5 mins
                                        </div>
                                        <div
                                            className={dateType === DateSelectType.LastHour ? "segment-select-item-selected " : "segment-select-item"}
                                            style={{minWidth: '60px'}}
                                            onClick={() => setDateRange(DateSelectType.LastHour)}>1 hr
                                        </div>
                                        <div
                                            className={dateType === DateSelectType.Last6Hrs ? "segment-select-item-selected " : "segment-select-item"}
                                            style={{minWidth: '60px'}}
                                            onClick={() => setDateRange(DateSelectType.Last6Hrs)}>6 hrs
                                        </div>
                                        <div
                                            className={dateType === DateSelectType.Last12Hrs ? "segment-select-item-selected " : "segment-select-item"}
                                            style={{minWidth: '60px'}}
                                            onClick={() => setDateRange(DateSelectType.Last12Hrs)}>12 hrs
                                        </div>
                                        <div
                                            className={dateType === DateSelectType.Last24Hrs ? "segment-select-item-selected " : "segment-select-item"}
                                            style={{minWidth: '60px'}}
                                            onClick={() => setDateRange(DateSelectType.Last24Hrs)}>24 hrs
                                        </div>
                                        <div
                                            className={dateType === DateSelectType.LastAvailable ? "segment-select-item-selected " : "segment-select-item"}
                                            style={{minWidth: '60px'}}
                                            onClick={() => setDateRange(DateSelectType.LastAvailable)}>Last Available
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"lift-header-column"}>
                                <div className="section-edit-row">
                                    <button className={"button search-button"} style={{marginLeft: '200px'}}
                                            onClick={findData}>
                                    <span className="material-icons"
                                          style={{verticalAlign: '-6px', paddingRight: '8px'}}>search</span>Find Data
                                    </button>
                                    {(summary_only) &&
                                        <div style={{marginLeft: '20px', display: 'inline-block'}}>
                                            <ButtonGlyph glyph={showDateChart ? "check_box" : "check_box_outline_blank"}
                                                         cn="material-symbols-rounded"
                                                         onClick={() => setShowDateChart(!showDateChart)}
                                                         style={{
                                                             fontSize: '24px',
                                                             verticalAlign: 'middle',
                                                             color: 'darkgray',
                                                             width: '40px'
                                                         }}/>
                                            <small><i>{showDateChart ? "Hide Chart" : "Show Chart"}</i></small>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '190px', display: 'inline-block'}}>Data is available from</label>
                                    <input type="text"
                                           style={{width: '160px'}}
                                           disabled
                                           value={Helper.dbDateToFormatTime(dataViewSet.range_start, 'll LTS')}
                                    />
                                </div>
                            </div>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '190px', display: 'inline-block'}}>Data is available to</label>
                                    <input type="text"
                                           style={{width: '160px'}}
                                           disabled
                                           value={Helper.dbDateToFormatTime(dataViewSet.range_end, 'll LTS')}
                                    />
                                </div>
                            </div>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '190px', display: 'inline-block'}}>Current Active Data Timestamp</label>
                                    <input type="text"
                                           style={{width: '160px', fontWeight: 'bold'}}
                                           disabled
                                           value={Helper.dbDateToFormatTime(dataViewSet?.current?.received_at, 'll LTS')}
                                    />
                                </div>
                            </div>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '190px', display: 'inline-block'}}>Number of Data Points</label>
                                    <input type="text"
                                           style={{width: '80px'}}
                                           disabled
                                           value={Helper.asNumberString(dataViewSet?.total_records || 0)}
                                    />
                                </div>
                                <button className={"button"} style={{marginLeft: '200px'}} disabled={!dataViewSet.is_filtered}
                                        onClick={clearFilterRange}>
                                    <span className="material-icons"
                                          style={{verticalAlign: '-6px', paddingRight: '8px'}}>delete</span>Clear Filter
                                </button>
                            </div>
                        </div>
                    </>
                }

                {(display.size_class !== DisplaySizeClass.MobilePortrait) &&
                    <>
                        <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                            <div className="lift-header-inline-column">
                                <div className="section-edit-row">
                                    <label style={{width: '200px', display: 'inline-block'}}>Select Start
                                        Date/Time</label>
                                    <input type="datetime-local" style={{display: 'inline-block'}} value={startDate || ""}
                                           onChange={(d) => onStartDateChange(d.target.value)}/>
                                </div>
                                <div className="lift-header-column">
                                    <label style={{width: '200px', display: 'inline-block'}}>Select End
                                        Date/Time</label>
                                    <input type="datetime-local" style={{display: 'inline-block'}}
                                           value={endDate || ""} onChange={(d) => onEndDateChange(d.target.value)}/>
                                </div>
                                <div className="lift-header-column">
                                    <div className="section-edit-row">
                                        <label style={{width: '200px', display: 'inline-block'}}>Choose Predefined
                                            Period</label>
                                        <div style={{display: 'inline-block'}}>
                                            <div
                                                className={dateType === DateSelectType.Last5Mins ? "segment-select-item-selected " : "segment-select-item"}
                                                style={{minWidth: '60px'}}
                                                onClick={() => setDateRange(DateSelectType.Last5Mins)}>5 mins
                                            </div>
                                            <div
                                                className={dateType === DateSelectType.LastHour ? "segment-select-item-selected " : "segment-select-item"}
                                                style={{minWidth: '60px'}}
                                                onClick={() => setDateRange(DateSelectType.LastHour)}>1 hr
                                            </div>
                                            <div
                                                className={dateType === DateSelectType.Last6Hrs ? "segment-select-item-selected " : "segment-select-item"}
                                                style={{minWidth: '60px'}}
                                                onClick={() => setDateRange(DateSelectType.Last6Hrs)}>6 hrs
                                            </div>
                                            <div
                                                className={dateType === DateSelectType.Last12Hrs ? "segment-select-item-selected " : "segment-select-item"}
                                                style={{minWidth: '60px'}}
                                                onClick={() => setDateRange(DateSelectType.Last12Hrs)}>12 hrs
                                            </div>
                                            <div
                                                className={dateType === DateSelectType.Last24Hrs ? "segment-select-item-selected " : "segment-select-item"}
                                                style={{minWidth: '60px'}}
                                                onClick={() => setDateRange(DateSelectType.Last24Hrs)}>24 hrs
                                            </div>
                                            <div
                                                className={dateType === DateSelectType.LastAvailable ? "segment-select-item-selected " : "segment-select-item"}
                                                style={{minWidth: '60px'}}
                                                onClick={() => setDateRange(DateSelectType.LastAvailable)}>Last Available
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"lift-header-column"}>
                                    <div className="section-edit-row">
                                        <button className={"button"} style={{marginLeft: '200px'}}
                                                onClick={findData}>
                                        <span className="material-icons"
                                              style={{verticalAlign: '-6px', paddingRight: '8px'}}>search</span>Find
                                            Data
                                        </button>
                                        {(summary_only) &&
                                            <div style={{marginLeft: '20px', display: 'inline-block'}}>
                                                <ButtonGlyph
                                                    glyph={showDateChart ? "check_box" : "check_box_outline_blank"}
                                                    cn="material-symbols-rounded"
                                                    onClick={() => setShowDateChart(!showDateChart)}
                                                    style={{
                                                        fontSize: '24px',
                                                        verticalAlign: 'middle',
                                                        color: 'darkgray',
                                                        width: '40px'
                                                    }}/>
                                                <small><i>{showDateChart ? "Hide Chart" : "Show Chart"}</i></small>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="lift-header-inline-column" style={{marginLeft: '50px'}}>
                                <div className="section-edit-row">
                                    <label style={{width: '190px', display: 'inline-block'}}>Data is available from</label>
                                    <input type="text"
                                           style={{width: '160px'}}
                                           disabled
                                           value={Helper.dbDateToFormatTime(dataViewSet.range_start, 'll LTS')}
                                    />
                                    <label
                                        style={{
                                            width: '50px',
                                            display: 'inline-block',
                                            textAlign: 'center'
                                        }}> to </label>
                                    <input type="text"
                                           style={{width: '160px'}}
                                           disabled
                                           value={Helper.dbDateToFormatTime(dataViewSet.range_end, 'll LTS')}
                                    />
                                </div>
                                <br/>
                                <br/>

                                {(summary_only) &&
                                    <div className="section-edit-row">
                                        <>
                                            <ButtonGlyph style={{color: `${dataViewSet.at_start || playing || dataViewSet.empty ? 'darkgray' : 'black'}`, fontSize: '40px', cursor: 'pointer', verticalAlign: '0'}} disabled={dataViewSet.at_start || playing || dataViewSet.empty} glyph="first_page"
                                                         onClick={() => move(1)}/>
                                            <div style={{display: 'inline-block'}} onClick={() => dataViewSet.at_start ? {} : advance(-1)}>
                                                <span className="material-icons quick-link" style={{
                                                    paddingLeft: '5px',
                                                    paddingRight: '5px',
                                                    fontSize: '40px',
                                                    cursor: 'pointer',
                                                    color: `${(dataViewSet.at_start || playing || dataViewSet.empty) ? 'darkgray' : 'black'}`
                                                }}>arrow_back</span>
                                            </div>

                                            <ButtonGlyph style={{
                                                color: `${(dataViewSet.at_start || !playing || dataViewSet.empty) ? 'darkgray' : 'red'}`,
                                                fontSize: '40px',
                                                cursor: 'pointer', verticalAlign: '0'
                                            }} disabled={dataViewSet.at_start || !playing || dataViewSet.empty} glyph="stop" onClick={stop}/>

                                            <div style={{
                                                display: 'inline-block',
                                                color: 'black',
                                                verticalAlign: '0px'
                                            }}>
                                                <div style={{display: 'block', textAlign: 'center'}}>
                                                    <div style={{fontSize: '28px'}}>{Helper.dbDateToFormatTime(dataViewSet.current?.received_at, 'll LTS')}</div>
                                                    {/*<div style={{fontSize: '28px'}}>{Helper.dbDateToFormatTime(dataViewSet.current?.received_at)}</div>*/}
                                                    <small>{Helper.asNumberString(dataViewSet.current_record)} of {Helper.asNumberString(dataViewSet.total_records)}</small>
                                                </div>
                                            </div>

                                            <ButtonGlyph style={{
                                                color: `${playing || dataViewSet.empty ? 'darkgray' : 'green'}`,
                                                fontSize: '40px',
                                                cursor: 'pointer', verticalAlign: '0'
                                            }} glyph="play_arrow" disabled={playing || dataViewSet.empty} onClick={play}/>

                                            <div style={{display: 'inline-block'}} onClick={() => dataViewSet.at_end ? {} : advance(1)}>
                                                  <span className="material-icons quick-link" style={{
                                                      paddingLeft: '5px',
                                                      paddingRight: '5px',
                                                      fontSize: '40px',
                                                      cursor: 'pointer',
                                                      color: `${(dataViewSet.at_end || playing || dataViewSet.empty) ? 'darkgray' : 'black'}`
                                                  }}>arrow_forward</span>
                                            </div>
                                            <ButtonGlyph style={{color: `${dataViewSet.at_end || playing || dataViewSet.empty ? 'darkgray' : 'black'}`, fontSize: '40px', cursor: 'pointer', verticalAlign: '0'}} disabled={dataViewSet.at_end || playing || dataViewSet.empty} glyph="last_page"
                                                         onClick={() => move(dataViewSet.total_records)}/>
                                        </>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }

                {(dataViewSet.empty) &&
                    <div className="form-message">
                        No data points available for distribution analysis.
                    </div>
                }

                <br/>
                <hr/>

                {(distributionSummary.length > 0 && (!summary_only || showDateChart)) &&
                    <>
                        <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                            <div className="lift-header-column">
                                <div className="section-edit-row">
                                    <label style={{width: '100px', display: 'inline-block'}}>Filtered Range</label>
                                    <input type="text"
                                           style={{width: '160px'}}
                                           disabled
                                           value={Helper.dbDateToFormatTime(filterStartDate, 'll LTS')}
                                    />
                                    <label
                                        style={{
                                            width: '50px',
                                            display: 'inline-block',
                                            textAlign: 'center'
                                        }}> to </label>
                                    <input type="text"
                                           style={{width: '160px'}}
                                           disabled
                                           value={Helper.dbDateToFormatTime(filterEndDate, 'll LTS')}
                                    />
                                    <button className={"small-button"} style={{marginLeft: '20px'}} onClick={applyFilter}>
                                            <span className="material-icons"  style={{verticalAlign: '-6px', paddingRight: '8px'}}>filter</span>Set Filter
                                    </button>
                                    <button className={"small-button"} style={{marginLeft: '20px'}}
                                            onClick={clearFilter}>
                        <span className="material-icons"
                              style={{verticalAlign: '-6px', paddingRight: '8px'}}>delete</span>Clear Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </>
                }


                <div id={"dist_chart_id"} hidden>
                    <div className="lv-dist-text">
                        This chart shows data point counts aggregated at 30 minute intervals.
                        It shows a general usage pattern for the selected period.
                    </div>
                    <br/>
                    <br/>
                    <div style={{width: '100%'}}>
                        <div id="distribution_chart" style={{height: '400px'}}>&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default LiftStatusDateSelect;